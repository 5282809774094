import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"

import { FaArrowAltCircleLeft } from "react-icons/fa"
import Layout from "../components/LayoutAlt.js"

export default function DietSinglePage({ data }) {
  const life = data.strapiLifestyles

  return (
    <Layout>
         <div className="sm:w-3/4 sm:ml-1/8  xs:mt-10 sm:mt-20 mb-20 xs:p-4 sm:p-0">
         <h1 className="font-playfairdisplay font-bold xs:text-4xl sm:text-5xl text-blue-alt">{life.title}</h1>

         <img src={life.image.url} alt="Country Living" className="mt-10 mb-4 w-full h-96 object-cover" />
         <div className="flex items-center font-opensans text-xs sm:text-sm text-gray-500 space-x-2">
                    <img src={life.author.avatar.formats.thumbnail.url} alt="Author" className="w-10 h-10 rounded-full border-2" />
                    <p className="font-bold text-blue-dark ">{life.author.name}</p>
                    <p className="">{life.date}</p>
                  </div>


            <Link to={`/lifestyles/${life.category}`}>
                <section className="font-opensans  text-white  bg-blue-alt xs:mt-10 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 
                        rounded-r-xl flex items-center">
                <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer" />
                <p className=" text-xs sm:text-base">/lifestyles/{life.category}/
                    {life.slug}</p>
                </section>
            </Link>

            <p className="articles text-gray-700">
                <ReactMarkdown children={life.content} />
            </p>



            <section className="mt-20">
               <Link to={`/lifestyles/${life.category}`}>
                    <button className="font-opensans font-bold text-white bg-blue-alt ring ring-blue-alt ring-offset-2 rounded-sm py-1 px-8">
                     Back
                    </button>
                    </Link>
               </section>
         </div>
         <Social />
    </Layout>
    
    )
  }
  export const query = graphql`
  query($slug: String!) {
    strapiLifestyles( slug: {eq: $slug} )  {
      title
      date(formatString: "LL")
      slug
      content
      category
      image {
            url
      }
      author {
        name
        avatar {
          formats {
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`